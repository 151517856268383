import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';
// import seoText from '../components/seo.txt';

import heroImg from '../images/undraw_feeling_proud_qne1.svg';
import wavesImg from '../images/waves.svg';
import wavesInvertedImg from '../images/wavesInverted.svg';
import webdeveloperImage from '../images/undraw_web_development_w2vv.svg';
import serverClusterImg from '../images/undraw_server_cluster_jwwq.svg';
import backendImg from '../images/undraw_programming_2svr.svg';
import mobileImg from '../images/undraw_mobile_development_8gyo.svg';
import cmsImg from '../images/undraw_wireframing_nxyi.svg';

import Layout from '../components/layout';
import SEO from '../components/seo';

// import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";
const IndexPage = () => {
  const { title, contactEmail, blog } = useSiteMetadata();

  return (
    <Layout>
      <SEO
        title="We want to make your next app"
        keywords={[
          `codemercenaries`,
          `code mercenaries`,
          `code shop`,
          `code`,
          `node`,
          `nodejs`,
          `elixir`,
          `php`,
          `ruby on rails`,
          `ruby`,
          `sinatra`,
          `react`,
          `reactjs`,
          `vue`,
          `vuejs`,
          `scss`,
          `tailwindcss`,
          `devops`,
          `aws`,
          `azure`,
          `gcp`,
          `google cloud provider`,
          `aws certified`,
          `postgres`,
          `mysql`,
          `mongodb`,
          `cassandra`,
          `express`,
          `api`,
          `terraform`,
          `cloudfront`,
          `kotlin`,
          `swift`,
          `objective-c`,
          `react-native`,
          `flutter`,
        ]}
      />
      <>
        {/* Hero */}
        <div className="pt-24">
          <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center">
              <h1 className="flex-col w-full my-4 text-5xl font-bold leading-tight text-center">
                Welcome to
                <br />
                {title}
              </h1>
              <p className="leading-normal text-2xl mb-8 text-center">
                We help you meet your goals on the web and give you the best
                return on your investment.
              </p>
              <a
                className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
                href="#what-we-do"
              >
                Get Started
              </a>
            </div>

            <object
              className="w-full md:w-3/6 px-3 py-1 text-center"
              type="image/svg+xml"
              data={heroImg}
            >
              <img className="w-full md:w-4/5 z-50" src={heroImg} />
            </object>
          </div>
        </div>

        {/* Waves SVG */}
        <div className="relative -mt-12 lg:-mt-24">
          <object type="image/svg+xml" data={wavesImg} />
        </div>

        {/* Alternate features */}
        <section id="what-we-do" className="bg-white border-b py-8">
          <div className="container max-w-5xl mx-auto m-8">
            <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
              What We Do
            </h2>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Front-end Development
                </h3>
                <p className="text-gray-600 mb-8">
                  {/* Need help building a modern user interface for your web app? */}
                  Starting a new progressive web app or updating your existing
                  app to modern user interface standards? We specialize in
                  multiple front-end Javascript frameworks, including React.js,
                  Vue.js, Next.js, and Angular.js. Whether you need a front-end
                  built from scratch or need to redevelop an older front-end,
                  our UI development showcases the industry’s best practices
                  with regards to code quality and test coverage. Let us build
                  you an engaging, modern UI that will impress your users and
                  help your business crush your competitors.
                  <br />
                </p>
              </div>
              <div className="w-full sm:w-1/2 p-6">
                <object
                  className="w-full sm:h-64 mx-auto"
                  type="image/svg+xml"
                  data={webdeveloperImage}
                >
                  <img
                    className="w-full sm:h-64 mx-auto"
                    src={webdeveloperImage}
                  />
                </object>
              </div>
            </div>

            <div className="flex flex-wrap flex-col-reverse sm:flex-row">
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <object
                  className="w-5/6 sm:h-64 mx-auto"
                  type="image/svg+xml"
                  data={backendImg}
                >
                  <img className="w-5/6 sm:h-64 mx-auto" src={backendImg} />
                </object>
              </div>
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <div className="align-middle">
                  <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                    Back-end Development
                  </h3>
                  <p className="text-gray-600 mb-8">
                    We offer a multitude of back-end related web services
                    including APIs, databases, payment systems and IoT
                    integration. Our APIs are typically built in Node.js or
                    Elixir. But we can also do Ruby, PHP, and Python. If your
                    business has an e-commerce component, then payment
                    integration is important to you. We have extensive
                    experience in integrating with both Stripe and Paypal. If
                    your business has an IoT requirement, we can bridge that
                    gap.
                    {/* as well as several hardware POS devices. */}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Infrastructure & DevOps
                </h3>
                <p className="text-gray-600 mb-8">
                  Want to outsource or migrate your Infrastructure? We can do
                  that. We can setup, and manage your infrastructure needs via
                  several cloud providers such as AWS, Google Cloud, Azure,
                  DigitalOcean and Heroku. Need help streamlining your DevOps
                  cycle? We can help you set that up and optimize it with modern
                  technologies so you can focus your time and energy improving
                  other areas of your business.
                  {/* We can help you
                  optimize that with modern technologies so you can focus your
                  energy on other areas of your business. */}
                  <br />
                </p>
              </div>
              <div className="w-full sm:w-1/2 p-6">
                <object
                  className="w-full sm:h-64 mx-auto"
                  type="image/svg+xml"
                  data={serverClusterImg}
                >
                  <img
                    className="w-full sm:h-64 mx-auto"
                    src={serverClusterImg}
                  />
                </object>
              </div>
            </div>

            <div className="flex flex-wrap flex-col-reverse sm:flex-row">
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <object
                  className="w-5/6 sm:h-64 mx-auto"
                  type="image/svg+xml"
                  data={mobileImg}
                >
                  <img className="w-5/6 sm:h-64 mx-auto" src={mobileImg} />
                </object>
              </div>
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <div className="align-middle">
                  <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                    Native and Hybrid Mobile Development
                  </h3>
                  <p className="text-gray-600 mb-8">
                    We possess vast experience in developing both hybrid mobile
                    apps and native mobile apps. A hybrid mobile app can be a
                    cheaper alternative to developing a native mobile app
                    because we can develop the hybrid mobile app using
                    conventional web technologies, and often times, if we
                    developed the web app for our clients as well, we can reuse
                    code. And if your mobile app has high performance
                    requirements, we can build a native mobile app for both iOS
                    and Android platforms.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  CMS Development
                </h3>
                <p className="text-gray-600 mb-8">
                  We offer many CMS related development services, including
                  custom theme development, plugin development, payment
                  integration, and redesign. We specialize in many different CMS
                  platforms such as WordPress, Drupal, Strapi and Prismic, with
                  WordPress being most popular with our clients. Building your
                  CMS can be a stressful process, since your CMS is often the
                  face of your brand, and you want it to resonate with your
                  users and make a great impression. We will use our vast
                  experience in building and designing CMSs to deliver a product
                  that you and your users will fall in love with.
                  <br />
                </p>
              </div>
              <div className="w-full sm:w-1/2 p-6">
                <object
                  className="w-full sm:h-64 mx-auto"
                  type="image/svg+xml"
                  data={cmsImg}
                >
                  <img className="w-full sm:h-64 mx-auto" src={cmsImg} />
                </object>
              </div>
            </div>
          </div>
        </section>

        {/* Title cards */}
        {/* <section className="bg-white border-b py-8">
              <div className="container mx-auto flex flex-wrap pt-4 pb-12">
                <h3 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
                  Text Cards
                </h3>
                <div className="w-full mb-4">
                  <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>

                <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                  <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                    <a
                      href="https://www.gatsbyjs.org/"
                      className="flex flex-wrap no-underline hover:no-underline"
                    >
                      <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                        Blazing Fast
                      </p>
                      <div className="w-full font-bold text-xl text-gray-800 px-6">
                        Static Progressive Web App
                      </div>
                      <p className="text-gray-800 text-base px-6 mb-5">
                        Why Gatsby? Gatsby is a static PWA (Progressive Web App)
                        generator. Gatsby loads only the critical HTML, CSS,
                        data, and JavaScript so your site loads as fast as
                        possible.
                      </p>
                    </a>
                  </div>
                  <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                    <div className="flex items-center justify-start">
                      <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                        Action
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                  <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                    <a
                      href="https://tailwindcss.com/"
                      className="flex flex-wrap no-underline hover:no-underline"
                    >
                      <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                        Tailwind is different
                      </p>
                      <div className="w-full font-bold text-xl text-gray-800 px-6">
                        State-of-the-art CSS Framework
                      </div>
                      <p className="text-gray-800 text-base px-6 mb-5">
                        Instead of opinionated predesigned components, Tailwind
                        provides low-level utility classes that let you build
                        completely custom designs without ever leaving your
                        HTML.
                      </p>
                    </a>
                  </div>
                  <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                    <div className="flex items-center justify-center">
                      <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                        Action
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                  <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                    <a
                      href="#"
                      className="flex flex-wrap no-underline hover:no-underline"
                    >
                      <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                        Open-Source
                      </p>
                      <div className="w-full font-bold text-xl text-gray-800 px-6">
                        Coded by{" "}
                        <a href="https://twitter.com/Sm0keDev">Sm0ke</a>{" "}
                      </div>
                      <p className="text-gray-800 text-base px-6 mb-5">
                        The source code is released under a license in which the
                        copyright holder grants users the rights to study,
                        change, and distribute the software to anyone and for
                        any purpose.
                      </p>
                    </a>
                  </div>
                  <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                    <div className="flex items-center justify-end">
                      <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                        Action
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

        {/* Pricing cards */}
        {/* <section className="bg-gray-100 py-8">
              <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
                <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                  Pricing
                </h1>
                <div className="w-full mb-4">
                  <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>

                <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
                  <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                    <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                      <div className="p-8 text-3xl font-bold text-center border-b-4">
                        Free
                      </div>
                      <ul className="w-full text-center text-sm">
                        <li className="border-b py-4">Thing</li>
                        <li className="border-b py-4">Thing</li>
                        <li className="border-b py-4">Thing</li>
                      </ul>
                    </div>
                    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                      <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                        £0 <span className="text-base">for one user</span>
                      </div>
                      <div className="flex items-center justify-center">
                        <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
                    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                      <div className="w-full p-8 text-3xl font-bold text-center">
                        Basic
                      </div>
                      <div className="h-1 w-full gradient my-0 py-0 rounded-t"></div>
                      <ul className="w-full text-center text-base font-bold">
                        <li className="border-b py-4">Thing</li>
                        <li className="border-b py-4">Thing</li>
                        <li className="border-b py-4">Thing</li>
                        <li className="border-b py-4">Thing</li>
                      </ul>
                    </div>
                    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                      <div className="w-full pt-6 text-4xl font-bold text-center">
                        £x.99 <span className="text-base">/ per user</span>
                      </div>
                      <div className="flex items-center justify-center">
                        <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                    <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                      <div className="p-8 text-3xl font-bold text-center border-b-4">
                        Pro
                      </div>
                      <ul className="w-full text-center text-sm">
                        <li className="border-b py-4">Thing</li>
                        <li className="border-b py-4">Thing</li>
                        <li className="border-b py-4">Thing</li>
                      </ul>
                    </div>
                    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                      <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                        £x.99 <span className="text-base">/ per user</span>
                      </div>
                      <div className="flex items-center justify-center">
                        <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

        {/* Waves SVG */}

        <div className="wave-top">
          <object type="image/svg+xml" data={wavesInvertedImg} />
        </div>

        {/* CTA block */}
        <section
          id="contact"
          className="container mx-auto text-center py-6 mb-12"
        >
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
            Let's Talk
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <a
            className="inline-block text-white no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
            href={'mailto:' + contactEmail}
          >
            {contactEmail}
          </a>
          {/* <a href="/contact">
            <button
              type="submit"
              className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
            >
              Contact
            </button>
          </a> */}
        </section>
      </>
    </Layout>
  );
};

export default IndexPage;
